import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup

const name = 'Melissa Steever'
const license = 'MA, LPC-MH'
const areas = [
    'Adolescents',
    'Behavioral Issues',
    'Anger Management',
    'Anxiety/ Depression',
    'Communication and Relationship Issues',
    'Parenting Concerns',
    'Trauma',
    'Juvenile Justice System',
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./melissa-steever.jpg' alt={name} layout="constrained" className="shadow" />)}
        >

            <p>Melissa Steever attended South Dakota State University majoring in Human Development and Family Studies. While attending college, she worked with abused and neglected children at Children's Home Society. Following graduation from SDSU, Melissa moved to Bloomington, Minnesota earning her Masters Degree in Psychotherapy at the The Adler Institute. During her three years in Minnesota, Melissa worked at Cornerstone Abuse Shelter with women and children fleeing domestic violence.</p>
            <p>When Melissa returned to Sioux Falls in 2003, she worked at Lutheran Social Services as a Family Based Services therapist providing in-home family therapy to adolescents involved with the Juvenile Justice System.</p>
            <p>Melissa continues to work with adolescents and adults struggling with Anxiety, Depression, Low Self-Esteem, Communication and Relationship Issues, Parenting Concerns, Trauma, Chemical Dependency Issues, Anger Management, Family Relationships, and Decision-Making.</p>
            <p>If you would like to schedule a session with Melissa please call <a href="tel:1-605-271-1348">605.271.1348</a> or email her at <a href="mailto:Melissa@IntegrativeWellnessSD.com">Melissa@IntegrativeWellnessSD.com</a>. Melissa accepts most insurances including Medicaid.</p>

        </Therapist>
    </Layout>
)

export default IndexPage
